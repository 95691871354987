import { Card, Container } from "@mui/material";
import React from "react";
import useResponsive from "src/hooks/useResponsive";
import background from "src/images/bgimage1.jpg";
const Background = ({ children }) => {
  const mdUp = useResponsive("up", "md");

  return mdUp ? (
    <Container maxWidth="lg">
      <Card
        sx={{
          maxWidth: 1400,
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "round",
        }}
      >
        {children}
      </Card>
    </Container>
  ) : (
    <>{children}</>
  );
};

export default Background;
