import { Box, Card, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoginForm from "src/pages/auth/Login/components/MainSection/login";
import Message from "./components/message";
import NoAccountSection from "./components/no-account-section";

import ImageCard from "../ImageCard";
import Logo from "src/components/Logo";
import GetStartedTitle from "../Header/GetStartedTitle";
import Background from "./components/background";
import Content from "./components/ContentStyle";


const MainSection = () => {
  return (
    <Background>
      <Container maxWidth="sm">
        <Content>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: { xs: 3, sm: 5 },
            }}
          >
            <Logo />
          </Box>
          <Message />
          <ImageCard />
          <LoginForm />

          <NoAccountSection />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GetStartedTitle />
          </Box>
        </Content>
      </Container>
    </Background>
  );
};

export default MainSection;
