import { Card, styled } from "@mui/material";
import React from "react";
import useResponsive from "src/hooks/useResponsive";
const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "95vh",
  flexDirection: "column",
  padding: theme.spacing(2, 4),
  marginTop: "10px",
  marginBottom: "10px",
  boxShadow: theme.shadows[10],
}));
const ContentStyleResponsive = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "95vh",
  flexDirection: "column",
  // padding: theme.spacing(6, 0),
}));
const Content = ({ children }) => {
  const mdUp = useResponsive("up", "md");
  return mdUp ? (
    <ContentStyle>{children}</ContentStyle>
  ) : (
    <ContentStyleResponsive>{children}</ContentStyleResponsive>
  );
};

export default Content;
